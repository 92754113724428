@tailwind base;
@tailwind components;
* {
  background-color: #fff6ed;
  scroll-behavior: smooth;
}
.overflow-auto::-webkit-scrollbar {
  width: 0px;
}
@tailwind utilities;

.aboutUsContainer {
  position: relative;
  height: 45vh;
  background-image: url("../src/Utils/aboutus.jpg");
  background-size: cover;
  background-position: center;

  overflow: hidden;
}

.aboutUsContainerOp {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobileDropDownContainer {
  height: calc(100% - 80px) !important;
  bottom: 0px !important;
}
